<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Proje Geçmişi</b-card-title>
    </b-card-header>
    <b-table
      v-if="details.length > 0"
      responsive="sm"
      :fields="fields"
      :items="details"
      striped
    >
      <template #cell(company)="data">
        <div class="d-flex align-content-start align-items-center">
          <div>
            <b-button
              v-b-tooltip.hover.top="'Proje Görüntüle'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              :href="'/app/projects/view/' + data.item.id"
              target="_blank"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div class="ml-1">
            <div>
              {{ data.item.company }}
            </div>
            <div class="font-small-2">
              <strong class="text-primary">Proje Adı:</strong> {{ data.item.title }}
            </div>
            <div class="font-small-2">
              <strong class="text-primary">Oluşturma Tarih:</strong> {{ data.item.created? moment(data.item.created).format('DD.MM.YYYY') : '-' }}
            </div>
          </div>
        </div>
      </template>
      <template #cell(username)="data">
        <div>
          {{ data.item.username }}
        </div>
        <div
          class="text-warning font-small-2"
        >
          <div class="text-primary font-small-2">
            {{ data.item.project_status }}
          </div>
        </div>
      </template>
    </b-table>
    <b-card-body v-else>
      <empty-alert />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BTable, VBTooltip,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'Details',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BButton,
    EmptyAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
          thClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Oluşturan',
          thClass: 'text-nowrap',
        },
      ],
    }
  },
  computed: {
    details() {
      return this.$store.getters['customerReports/projects']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('customerReports/getCatalogs', this.$route.params.id)
    },
  },
}
</script>
