<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Proje Talep Geçmişi</b-card-title>
    </b-card-header>
    <b-table
      v-if="details.length > 0"
      responsive="sm"
      :fields="fields"
      :items="details"
      striped
    >
      <template #cell(company)="data">
        <div class="d-flex align-content-start align-items-center">
          <div>
            <b-button
              v-b-tooltip.hover.top="'Talebi Görüntüle'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              :href="'/app/projects/view/' + data.item.id"
              target="_blank"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div class="ml-1">
            <div>
              {{ data.item.company }}
            </div>
            <div
              class="font-small-2"
            >
              <strong class="text-primary">Talep Tarihi:</strong> {{ data.item.created? moment(data.item.created).format('DD.MM.YYYY') : '-' }}
            </div>
          </div>
        </div>
      </template>
      <!--      <template #cell(service_status)="data">-->
      <!--        <div>-->
      <!--          <span-->
      <!--            v-if="data.item.sent && data.item.id_offers"-->
      <!--            class="text-success"-->
      <!--          >-->
      <!--            Teklif Gönderildi-->
      <!--          </span>-->
      <!--          <span-->
      <!--            v-else-if="data.item.sent"-->
      <!--            class="text-danger"-->
      <!--          >-->
      <!--            Talep Beklemede-->
      <!--          </span>-->
      <!--          <span-->
      <!--            v-else-->
      <!--            class="text-warning"-->
      <!--          >-->
      <!--            Talep Hazırlanıyor-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div class="text-muted font-small-2">-->
      <!--          {{ data.item.username }}-->
      <!--        </div>-->
      <!--      </template>-->
    </b-table>
    <b-card-body v-else>
      <empty-alert />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BButton, BTable, VBTooltip,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'

export default {
  name: 'OfferRequests',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BButton,
    EmptyAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
          thClass: 'text-nowrap',
        },
        {
          key: 'project_status',
          label: 'DURUM',
          thClass: 'text-nowrap',
        },
      ],
    }
  },
  computed: {
    details() {
      return this.$store.getters['customerReports/projectRequests']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('customerReports/getProjectRequests', this.$route.params.id)
    },
  },
}
</script>
