<template>
  <div>
    <b-tabs
      pills
      justified
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Müşteri Detayı</span>
          </div>
        </template>
        <customer-info />
        <related-persons />
        <activities />
        <customer-invoice />
        <customer-address :is-report="true" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="MessageCircleIcon" />
            <span>Görüşmeler</span>
          </div>
        </template>
        <interviews />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Teklifler</span>
          </div>
        </template>
        <offers />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="FileTextIcon" />
            <span>Projeler</span>
          </div>
        </template>
        <projects />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon icon="SettingsIcon" />
            <span>Proje Talepleri</span>
          </div>
        </template>
        <project-requests />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import RelatedPersons from '@/views/Admin/Customers/View/RelatedPersons.vue'
import Activities from '@/views/Admin/Customers/View/Activities.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerAddress from '@/views/Admin/Customers/View/CustomerAddress.vue'
import Interviews from '@/views/Admin/Reports/Customers/components/Interviews.vue'
import Offers from '@/views/Admin/Reports/Customers/components/Offers.vue'
import Projects from '@/views/Admin/Reports/Customers/components/Projects.vue'
import ProjectRequests from '@/views/Admin/Reports/Customers/components/ProjectRequests.vue'

export default {
  name: 'Detail',
  components: {
    BTabs,
    BTab,
    CustomerInfo,
    RelatedPersons,
    Activities,
    CustomerInvoice,
    CustomerAddress,
    Interviews,
    Offers,
    Projects,
    ProjectRequests,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/dataItem']
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id)
    },
  },
}
</script>
